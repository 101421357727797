@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
@import './colors';
@import './fonts';
@import './mixins';
$font-family: 'Avenir';
$black: #000;
$white: #fff;

.footer_wrapper {
  background: #1a1d21;
  padding: 7.8rem 7.5rem;

  .container {
    @include fwcontainer;

    .row1 {
      @include flex(row, center, space-between);
      gap: 5rem;
      padding: 2rem 0;

      .column1 {
        .followWrapper {
          @include flex(row, center, space-around);
          gap: 2.5rem;
          .followText {
            color: #FFF;
            text-transform: uppercase;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 15px; /* 93.75% */
            letter-spacing: 0.48px;
          }
          .socialLinks {
            @include flex(row, center, space-between);
            gap: 1rem;
            cursor: pointer;
            svg:hover path {
              fill: white;
            }
            svg:hover circle {
              stroke: white;
            }
          }
        }
        .supportWrapper {
          @include flex(row, center, center);
          .instantText {
            text-transform: uppercase;
            color: $primary;
            font-size: $normal-big;
            font-weight: 700;
            font-family: $font-family;
            margin: 0 1rem;
          }
          .whatsappSupportWrapper {
            @include flex(row, center, space-around);
            .whatsappLinkWrapper {
              cursor: pointer;
            }
          }
          .separatorText {
            color: $white;
            font-family: Quicksand;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin: -5px 10px 0px 10px;
          }
          .feedbackPoint {
            color: $white;
            font-family: Quicksand;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin: -5px 5px 0px 5px;
            cursor: pointer;
            border-radius: 5px;
            background: var(--alias-primary-primary-study-iq-colour, #423dcf);
            padding: 10px;
          }
        }
      }
    }

    @include respond(mobile) {
      .row1 {
        display: none;
      }
    }

    hr {
      border: 1px dashed rgba(255, 255, 255, 0.4);
    }

    @include respond(mobile) {
      hr {
        display: none;
      }
    }

    .row2 {
      @include flex(row, flex-start, space-between);
      gap: 0.5rem;
      margin-top: 5rem;

      .column2 {
        padding: 1rem;
        @include respond(mobile) {
          &:last-child {
            width: 100%;
          }
        }
        .footerInfoText {
          color: $white;
          font-family: $font-family;
          font-size: $normal;
          font-weight: 500;
          line-height: 3rem;
        }
        .footerInformation {
          width: 22rem;
          border-right: 0.1rem solid rgba(255, 255, 255, 0.4);
          img {
            cursor: pointer;
          }
        }

        @include respond(mobile) {
          .footerInformation {
            border: none;
          }
        }

        @include respond(tablet) {
          .footerInformation {
            border: none;
          }
        }

        .linkHead {
          color: #FFF;
          text-transform: uppercase;
          font-family: $font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: 15px; /* 93.75% */
          letter-spacing: 0.48px;
        }

        .footerLinks {
          font-family: $font-family;
          margin-left: -4rem;
          color: $white;
          font-size: $normal;
          font-weight: 500;
          line-height: 3rem;
          margin-top: 2rem;
          color: rgba(255, 255, 255, 0.40);

          ul {
            list-style: none;
          }

          a {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.40);
            font-family: $font-family;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.42px;
            cursor: pointer;
            &:hover{
              color: #FFF;
            }
          }
        }

        .GoogleBtn {
          margin-top: 16.5px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          @include respond(mobile) {
            display: none;
          }
        }
        .playStoreFooterBtn {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          line-height: initial;
        }
      }

      @include respond(mobile) {
        .otherCourses {
          display: block;
        }
      }

      .linksBlock {
        display: none;
        line-height: 5rem;

        @include respond(mobile) {
          display: block;
        }
        .supportWrapper {
          @include flex(row, center, flex-start);
          @include respond(mobile) {
            gap: 8px;
          }
          .supportText {
            text-transform: uppercase;
            font-family: $font-family;
            font-size: $normal;
            color: $primary;
            font-size: $normal;
            font-weight: 700;
          }
          .whatsappWrapper {
            @include flex(row, center, flex-start);
            @include respond(mobile) {
              gap: 8px;
            }
          }
          .separatorText {
            color: $white;
            font-family: Quicksand;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }

          .queryBtn {
            border-radius: 5px;
            background: #423dcf;
            // height: 10px;
            padding: 5px 10px;
            color: $white;
            font-family: Quicksand;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            cursor: pointer;
            margin: 1rem 0;
          }

          .feedbackText {
            color: $white;
            font-family: Quicksand;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }

        .miniSocialLinks {
          @include flex(row, center, space-between);
          gap: 0.2rem;
          // border: 1px solid white;
          @include respond(mobile) {
            justify-content: flex-start;
            gap: 8px;
            a{
              width: 20px;
            }
          }
          margin-top: 1rem;
        }
      }
    }
  }
}

@include respond(mobile) {
  .footer_wrapper {
    padding: 1rem;
  }
}
